const Login = () => import('@/views/login')
const Layout = () => import ('@/views/layout')
const Home = () => import ('@/views/home')
const MiaoSha = () => import ('@/views/miaoSha')
const GoodDetail = () => import ('@/views/goodDetail')
const GoodList = () => import ('@/views/goodList')
const PlaceOrder = () => import('@/views/placeOrder')
const Search = () => import('@/views/search')
const Cart = () => import('@/views/cart')
const MyOrder = () => import('@/views/myOrder')
const MyCoupon = () => import('@/views/myCoupon')
const PointMall = () => import('@/views/pointMall')
const ConfirmOrder = () => import('@/views/confirmOrder')
const PayResult = () => import('@/views/payResult')
const OrderDetail =() => import('@/views/orderDetail')
const Evaluation =() => import('@/views/evaluation')

export default [{
		path: '/',
		name: 'index',
		component: Layout,
		redirect: {
			name: 'home'
		},
		children: [{
			path: 'home',
			name: 'home',
			component: Home,
			meta: {
				title: '首页',
			}
		},
		{
			path: 'placeOrder',
			name: 'placeOrder',
			component: PlaceOrder,
			meta: {
				title: '在线下单',
				login: true
			}
		},
		{
			path: 'miaoSha',
			name: 'miaoSha',
			component: MiaoSha,
			meta: {
				title: '限时秒杀',
				login: true
			}
		},
		{
			path: 'goodDetail',
			name: 'goodDetail',
			component: GoodDetail,
			meta: {
				login: true
			}
		},
		{
			path: 'goodList',
			name: 'goodList',
			component: GoodList,
			meta: {
				login: true
			}
		},
		{
			path: 'search',
			name: 'search',
			component: Search,
			meta: {
				title: '搜索',
				login: true
			}
		},
		{
			path: 'cart',
			name: 'cart',
			component: Cart,
			meta: {
				title: '购物车',
				login: true
			}
		},
		{
			path: 'myOrder',
			name: 'myOrder',
			component: MyOrder,
			meta: {
				title: '我的订单',
				login: true
			}
		},
		{
			path: 'myCoupon',
			name: 'myCoupon',
			component: MyCoupon,
			meta: {
				title: '我的优惠券',
				login: true
			}
		},
		{
			path: 'pointMall',
			name: 'pointMall',
			component: PointMall,
			meta: {
				title: '积分商城',
				login: true
			}
		},
		{	
			path: 'confirmOrder',
			name: 'confirmOrder',
			component: ConfirmOrder,
			meta: {
				title: '确认订单',
				hideNav: true,
				login: true
			}
		},
		{	
			path: 'payResult',
			name: 'payResult',
			component: PayResult,
			meta: {
				title: '支付成功',
				hideNav: true,
				login: true
			}
		},
		{	
			path: 'orderDetail',
			name: 'orderDetail',
			component: OrderDetail,
			meta: {
				title: '订单详情',
				hideNav: true,
				login: true
			}
		},
		{	
			path: 'evaluation',
			name: 'evaluation',
			component: Evaluation,
			meta: {
				title: '评价',
				hideNav: true,
				login: true
			}
		},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			title: '登录'
		}
	},
	{
		path: '*',
		redirect: {
			name: 'home'
		}
	}
]