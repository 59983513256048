export default {
    // 封装平台centreId
    centreId: 24,
    //登录
    login: '/wxapi/pcLogin',
    //退出
    logout: '/wxapi/pcLogout',
    //获取用户信息
    getByToken: '/wxapi/getByToken',
	//附近专柜
	shopNearest: '/wxapi/shop/nearest',
    //购物车数量
    cartTotal: '/wxapi/cart/total',
    //专区标签
    banner: '/wxapi/shop/banners',
    //商品列表
    products: '/wxapi/shop/products',
    //公告
    notice: '/wxapi/shop/notice',
    //快捷分类
    subject: '/wxapi/shop/subject',
    //分类
    category: '/wxapi/category',
    //加入购物车
    addCart: '/wxapi/cart/add',
    //未领取优惠券
    logincoup: '/wxapi/logincoup',
    //领取优惠券
    recieveCoup: '/wxapi/recieveCoup',
    //优惠券
    getCoupon: '/wxapi/coupon',
    //购物车列表
    cartList: '/wxapi/cart/list',
    //购物车删除
    cartDel: '/wxapi/cart/del',
    //购物车勾选删除
    cartDelAll: '/wxapi/cart/delall',
    //购物车数量修改
    cartEdit: '/wxapi/cart/edit',
    //商品详情
    productDetail: '/wxapi/shop/products/detail',
    //商品规格
    productSku: '/wxapi/shop/products/sku',
    //商品评价
    comments: '/wxapi/shop/products/detail/comments',
    //预提交订单
    precreate: '/wxapi/order/precreate',
    //获取运费模板
    getDispatchList: '/wxapi/getDispatchList',
    //获取积分
    getMemberIntegral: '/wxapi/getMemberIntegral',
    //创建订单
    createOrder: '/wxapi/order/create',
    //余额支付
    yupay: '/wxapi/order/yupay',
    //微信支付
    apppay: '/wxapi/order/apppay',
    //订单信息
    orderInfo: '/wxapi/pcOrder',
    //收货地址
    addressList: '/wxapi/mem/addressList',
    //保存地址
    saveAddress: '/wxapi/mem/saveAddress',
    //修改地址
    updAddress: '/wxapi/mem/updAddress',
    //订单列表
    orderList: '/wxapi/order/list',
    //取消订单
    orderCancel: '/wxapi/order/cancel',
    //单品取消
    goodsCancel: '/wxapi/order/goods/cancel',
    //图片上传
    upload: '/platform/config/upload',
    //评价
    addComment: '/wxapi/product/comment/add'
}