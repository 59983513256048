import Vue from 'vue'
const _this = new Vue()

export default {
    SET_USER_INFO({commit, state}, userInfo) {
        commit('SET_USER_INFO', userInfo)
    },

    async GET_USER_INFO({commit, state}) {
        await _this.$https.get(_this.$api.getByToken)
                .then((res) => {
                    commit('SET_USER_INFO', res.data)
                    commit('SET_SHOP_INFO', res.shop)
                })
    },

    GET_CART_TOTAL({commit, state}) {
        _this.$https.get(_this.$api.cartTotal,{
            centreId: state.userInfo.centreId,
            memberId: state.userInfo.id
        })
        .then((res) => {
            commit('SET_CART_TOTAL', res.data)
        })
    },

    async GET_SHOP_ID({commit, state}) {
        await _this.$https.get(_this.$api.shopNearest,{
            teamName: '',
            longitude: 0,
            latitude: 0,
            limit: 10,
            centreId: _this.$api.centreId,
            flag: 2		//1平台 2商户
        }).then((res) => {
            let dataObj = res.data[0]
            commit('SET_SHOP_ID', dataObj.id)
        })
    },
}