import { setToken, removeToken, setStorage } from '@/utils/auth'

export default {
    LOGIN_IN(state, loginInfo) {
        let {token, userInfo, shopInfo} = loginInfo
        setToken(token)
        state.token = token
        state.userInfo = userInfo
        state.shopInfo = shopInfo
    },

    LOGIN_OUT(state) {
        removeToken()
        state.token = ''
        state.userInfo = {}
    },

    SET_USER_INFO(state, userInfo) {
        state.userInfo = userInfo
    },

    SET_SHOP_INFO(state, shopInfo) {
        state.shopInfo = shopInfo
    },

    SET_CART_TOTAL(state, cartTotal) {
        state.cartTotal = cartTotal
    },

    SET_CART_ITEMS(state, cartItems) {
        state.cartItems = cartItems
        setStorage('cartItems', cartItems)
    },

    SET_SHOP_ID(state, shopId) {
        state.shopId = shopId
    }
}